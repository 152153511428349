@import ../../environment

.btn
  &[class*="-outline-"]
    &:not(:hover)
      background: $white

label.btn
  &:not(disabled)
    cursor: pointer

.input-group-append, .input-group-prepend
  > .btn
    border-width: 1px
