@import ../../environment

.collapse
  max-height: 100vh
  overflow-y: auto
  transition: max-height .6s ease-out

  &:not(.show)
    max-height: 0
    display: block
    overflow-y: hidden
    transition: max-height .3s ease-out

    +media-breakpoint-up(sm)
      .navbar-expand-sm &
        max-height: none

    +media-breakpoint-up(md)
      .navbar-expand-md &
        max-height: none

    +media-breakpoint-up(lg)
      .navbar-expand-lg &
        max-height: none
