@import ../environment

.page-header
  display: flex
  flex-wrap: wrap
  flex-direction: row
  align-items: center
  margin-bottom: spacer(4)

.page-header--head
  display: flex
  align-items: center

.page-header--title
  flex: 1 1 1%
  margin: 0
  line-height: $headings-line-height
  word-wrap: break-word
  +font-size($h1-font-size)

.page-header--badge
  padding: spacer(3)

  up-modal, up-drawer &
    +font-size($h2-font-size)

.page-header--actions
  margin-top: 0
  margin-left: auto

  display: flex
  flex-shrink: 0
  align-items: flex-start
  flex-wrap: wrap
  max-width: 100%

  gap: spacer(1)

  @media print
    display: none !important
