@import ../environment

.date-card
  position: relative
  display: flex
  align-items: stretch
  margin-bottom: spacer(3)

  &.-highlighted
    .date-card--day
      background-color: $primary
      border-top: $border-width solid $primary
      border-bottom: $border-width solid $primary
      border-left: $border-width solid $primary

.date-card--day
  flex: 0 0 80px
  line-height: 100%
  background-color: $black
  border-top: $border-width solid $black
  border-bottom: $border-width solid $black
  border-left: $border-width solid $black
  border-top-left-radius: $border-radius
  border-bottom-left-radius: $border-radius
  color: $gray-1
  font-weight: $font-weight-bold
  text-transform: uppercase

  +media-breakpoint-down(xs)
    flex-basis: 50px

.date-card--day-content
  display: flex
  width: 100%
  height: 100%
  justify-content: center
  align-items: center

.date-card--header
  margin-bottom: 7px

  &.-today
    font-weight: bold
    text-transform: uppercase

.date-card--content
  flex: 1 1 auto
  padding: $card-spacer-y $card-spacer-x
  background-color: white
  border-top: $border-width solid $black
  border-bottom: $border-width solid $black
  border-right: $border-width solid $black
  border-top-right-radius: $border-radius
  border-bottom-right-radius: $border-radius

.date-card--occupancy
  position: absolute
  top: $card-spacer-y
  right: $card-spacer-x

  >a
    color: $black

  &.-exhausted, &.-exhausted > a
    color: $danger
