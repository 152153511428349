@import ../environment

.action-bar
  display: flex
  flex-wrap: wrap
  margin-top: spacer(4)
  .btn
    margin-bottom: spacer(2)
    margin-right: spacer(2)

.action-bar--end
  display: flex
  flex-wrap: wrap

  margin-top: spacer(4)
  +media-breakpoint-up(md)
    margin-top: 0
    margin-left: auto
