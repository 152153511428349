@import ../environment

.room-card
  +margin-y(spacer(4))

.room-card--header
  display: flex
  align-items: center
  gap: spacer(2)
  text-transform: uppercase
  margin-bottom: spacer(2)

.room-card--table
  border: $border-width solid $black
  border-radius: $card-border-radius


