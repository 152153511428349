@import ../../environment

.custom-file
  z-index: 0

.custom-file-input
  cursor: pointer

  &:disabled
    cursor: not-allowed

.custom-file-label
  +text-truncate
  padding-right: 5rem // button

.custom-file-preview
  margin-top: $form-group-margin-bottom
  max-width: 500px
  max-height: 500px
