@import ../../environment

.table
  thead:first-child
    th
      border-top-width: 0

  tbody:first-child
    > tr:first-child
      > th,
      > td
        border-top-width: 0

.table-hover
  tr
    transition: background-color $transition-quick
