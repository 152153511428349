@import ../environment

.dish-reservation--toggle
  display: flex
  align-items: baseline
  flex-wrap: wrap

.dish-reservation--dish
  margin-left: 2.25em
  font-style: italic
