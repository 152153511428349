@import ../environment

.maybe-printed
  &.-not-printed
    color: lighten($black, 50%)
    text-decoration: line-through

    @media print
      display: none

.maybe-printed--toggle
  display: inline-block
  padding-left: spacer(2)
  +fa-icon(hide)

  @media print
    display: none

  .printable.-not-printed &
    +fa-icon(show)
