@import ../../environment

up-modal
  // Use Bootstrap's modal z-index. Unpoly's default of 10000 conflicts with too many libraries.
  z-index: $zindex-modal

up-modal-box
  border-radius: 0.75rem
  padding: $page-spacing-sm
  +media-breakpoint-up(md)
    padding: $page-spacing-md

up-drawer
  margin: 0
  max-width: 100%

up-drawer-box
  padding: spacer(3)
  padding-top: spacer(5)

up-modal-dismiss, up-drawer-dismiss
  padding: 0
  margin: 0
  border: none
  background: transparent
  pointer-events: auto
  color: #888

  font-size: 32px
  text-align: center

  &:hover
    opacity: 0.8
