@import ../../environment

.custom-select
  cursor: pointer

  .focused > &,
  *:focus > &
    color: $input-focus-color
    background-color: $input-focus-bg
    border-color: $input-focus-border-color
    box-shadow: $input-box-shadow, $input-focus-box-shadow
