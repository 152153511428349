@import '@fortawesome/fontawesome-free/scss/variables';

// Add icons here by giving them a useful name (like "date-picker") and reference a Font Awesome Sass variable.

$used-fa-icons: (
  yes: $fa-var-check,
  no: $fa-var-minus,
  add: $fa-var-plus,
  remove: $fa-var-times,
  search: $fa-var-search,
  menu: $fa-var-bars,
  user: $fa-var-user,
  logo: $fa-var-robot,
  occupancy: $fa-var-user,
  info: $fa-var-info-circle,
  check: $fa-var-check,
  show: $fa-var-eye,
  hide: $fa-var-eye-slash,
);

@mixin fa-icon($icon) {
  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: fa-content(map-get($used-fa-icons, $icon));
  }
}
