@import environment

html
  font-size: $root-font-size

body
  &.-loading-indicator
    &,
    button,
    [type="button"],
    [type="reset"],
    [type="submit"],
    [up-href],
    a,
    .btn
      cursor: progress !important

  &[data-env='test'] *
    text-transform: none !important

input[type="number"]
  text-align: right

h1
  +media-breakpoint-down(xs)
    font-size: $h4-font-size

%required-label
  &::after
    content: ' *'
    font-weight: bold
    color: $danger

label
  &.required
    &:not(.boolean)
      @extend %required-label
    &.boolean
      > span
        @extend %required-label

dd:last-child,
p:last-child
  margin-bottom: 0
