@import ../environment

.gravatar
  border-radius: 100% // crop image in a circle
  +margin-y(-10px) // bleed into vertical cell padding to not push up row height
  margin-right: 5px // spacing to user name

  &.-placeholder
    padding: 13.5px 14.63px // this results in a total size of 45 x 45 px for the <i> element
    color: $black
    background-color: $gray-1