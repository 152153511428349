@use 'sass:math'

@import bootstrap/scss/mixins
@import ./mixins/bootstrap
@import ./mixins/icons
@import ./mixins/svg_uri

=dimensions($width, $height)
  width: $width
  +height($height)

=height($height)
  height: $height
  line-height: floor(math.div($height, 2)) * 2 // Don't use odd line-height values. Browser randomly place content at the given value, or the preceding even number, or the following even number.

=only-in-tests
  body[data-env='test'] &
    @content

=not-in-tests
  body:not([data-env='test']) &
    @content

=within-modal
  .modal-content &
    @content

=when-modal-open
  body.-modal-open &
    @content

=margin-y($margin)
  margin-top: $margin
  margin-bottom: $margin

=margin-x($margin)
  margin-left: $margin
  margin-right: $margin

=padding-x($amount)
  padding-left: $amount
  padding-right: $amount

=padding-y($amount)
  padding-top: $amount
  padding-bottom: $amount

=hide-last-margins
  >*:last-child
    margin-bottom: 0 !important
    >*:last-child
      margin-bottom: 0 !important
      >*:last-child
        margin-bottom: 0 !important
        >*:last-child
          margin-bottom: 0 !important
