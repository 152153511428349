@import ../environment

.dish-card
  position: relative
  display: flex
  align-items: stretch
  margin-bottom: spacer(3)

.dish-card--header
  text-transform: uppercase

.dish-card--content
  flex: 1 1 auto
  padding: $card-spacer-y $card-spacer-x
  background-color: white
  border: $border-width solid $black
  border-radius: $border-radius

.dish-card--occupancy
  position: absolute
  top: $card-spacer-y
  right: $card-spacer-x

  >a
    color: $black

.dish-card--actions
  position: absolute
  bottom: $card-spacer-y
  right: $card-spacer-x

.dish-card--reserve-action
  &.-meal-reserved
    color: $success
    +font-size($h5-font-size)
