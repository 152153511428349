@import ../mixins

// Some elements need to be displayed in tests, e.g. form controls that were replaced by a custom control
// where we still need the test to be able to locate the form field. You may apply this class to them.

.ghost-in-tests
  +only-in-tests
    display: block !important
    opacity: 0.1
    position: absolute
