@import '../environment'

$fa-font-path: '@fortawesome/fontawesome-free/webfonts'
$fa-css-prefix: 'icon'

@import '@fortawesome/fontawesome-free/scss/variables'
@import '@fortawesome/fontawesome-free/scss/mixins'
@import '@fortawesome/fontawesome-free/scss/core'
@import '@fortawesome/fontawesome-free/scss/larger'
@import '@fortawesome/fontawesome-free/scss/fixed-width'
@import '@fortawesome/fontawesome-free/scss/list'
@import '@fortawesome/fontawesome-free/scss/bordered-pulled'
@import '@fortawesome/fontawesome-free/scss/animated'
@import '@fortawesome/fontawesome-free/scss/rotated-flipped'
@import '@fortawesome/fontawesome-free/scss/stacked'
@import '@fortawesome/fontawesome-free/scss/screen-reader'

@import '@fortawesome/fontawesome-free/scss/solid'

@each $name, $content in $used-fa-icons
  .#{$fa-css-prefix}-#{$name}:before
    content: fa-content($content)
