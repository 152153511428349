@import ../../environment

.dropdown
  &.focused,
  &:focus
    outline: none

.dropdown-menu
  float: none
  display: block
  visibility: hidden

  &.show
    visibility: visible

.dropdown-item
  white-space: normal
  word-wrap: break-word
