@import ../environment

.notes-for-kitchen
  display: flex
  color: $danger
  margin-bottom: spacer(3)

.notes-for-kitchen--notes
  margin-top: - spacer(4)
  margin-left: spacer(2)
  white-space: pre-wrap
