@use 'sass:math'

$root-font-size: 18px
$root-font-size-increase-over-bootstrap-default: math.div($root-font-size, 16px)

// Colors from ci.makandra.de
$red: #e61d47
$blue: #0d3f94
$blue-2: #20304c
$black: #141210
$gray-1: #ccd4d9
$gray-2: #4d5456
$white: #ffffff

// Generated extra colors
$blue-3: #98C1D9
$green: #6BD425
$yellow: #FFAE03

// Animation durations
$transition-distinct: .21s
$transition-quick: .14s
$transition-subtle: .07s

// Bootstrap colors
$primary: lighten($blue, 10%)
$danger: $red
$warning: $yellow
$success: $green
$info: $blue-3
$dark: $black
$body-color: $black

// Typography
$font-family-sans-serif: Roboto, sans-serif
$font-family-base: $font-family-sans-serif
$font-weight-normal: 300
$font-weight-bold: 700

$headings-font-weight: $font-weight-bold

$rfs-rem-value: math.div($root-font-size, 1px)
$rfs-base-font-size: 1.1rem
$rfs-factor: 100
$enable-responsive-font-sizes: true

// Components
$dropdown-link-hover-bg: rgba($primary, .1)

$custom-control-indicator-bg-size: (50% * $root-font-size-increase-over-bootstrap-default) (50% * $root-font-size-increase-over-bootstrap-default) // Fix ugly radio bullet offset

$navbar-nav-link-padding-x: .75rem
$navbar-dark-active-color: $red
$navbar-dark-brand-color: $gray-2
$navbar-dark-brand-hover-color: $gray-2

// Bootstrap-wide switches
$enable-shadows: true
$enable-gradients: false

@import bootstrap/scss/functions
@import bootstrap/scss/variables

$page-spacing-sm: math.div($grid-gutter-width, 2)
$page-spacing-md: $grid-gutter-width
