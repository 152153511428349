@import ../environment

.day-menu-dish-autocomplete--suggestions
  margin-bottom: spacer(2)
  &:empty
    display: none

.day-menu-dish-autocomplete--suggestions > .day-menu-autocomplete--suggestion
  cursor: pointer
  list-style: none
  color: #1153c3
  &:hover
    text-decoration: underline
    color: #0b357d
