@use 'sass:math'

@import ../environment

.flashes
  opacity: 0.95
  pointer-events: none

  @media print
    display: none

  +not-in-tests
    position: fixed
    z-index: 99999
    top: math.div($grid-gutter-width, 2)
    left: math.div($grid-gutter-width, 2)
    right: math.div($grid-gutter-width, 2)

  .layoutless &
    position: static

.flashes--flash
  pointer-events: auto
  max-width: map-get($container-max-widths, sm) - $grid-gutter-width
  margin: 0 auto spacer(2)
  word-wrap: break-word
  box-shadow: .2rem .2rem .1rem 0 rgba($black, .1)

  a
    color: inherit
    font-weight: bold
    text-decoration: underline

  button:focus
    outline: 0
