@import ../environment

.cleaning-job .square
  height: 24px
  width: 24px
  border: 1px solid black

.cleaning-job--header
  display: flex

.cleaning-job--header > .form-group
  margin-right: spacer(1)
  margin-left: spacer(1)

